import React, { useState } from "react";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import { GoogleMap } from "../components/GoogleMap";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/Textfield";
import MenuItem from "@material-ui/core/MenuItem";
import { Layout } from "../components/Layout";
import Button from "@material-ui/core/Button";
import { SvgIcon } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import CardContent from "@material-ui/core/CardContent";
import Seo from "../components/Seo/Seo";
import SendIcon from "@material-ui/icons/Send";
import CircularProgress from "@material-ui/core/CircularProgress";
import ReCAPTCHA from "react-google-recaptcha";

import { useStyles } from "../pagesStyles/contact.styling";

import PhoneIcon from "../assests/svg/phone.svg";
import HouseIcon from "../assests/svg/house.svg";

const subjectOptions = [
  {
    value: "general",
    label: "General",
  },
  {
    value: "enquiry",
    label: "Enquiry",
  },
  {
    value: "other",
    label: "Other",
  },
];

const breadcrumbs = [
  {
    name: "Home",
    link: "/",
  },
  {
    name: "Contact",
  },
];

const Contact = () => {
  const classes = useStyles();
  const desktop = useMediaQuery("(min-width:769px)");
  const [form, setForm] = useState({});
  const [loading, setLoading] = useState(false);
  const [formSent, setFormSent] = useState(false);
  const [token, setToken] = useState(null);

  const handleTextFields = (event) => {
    const { name, value } = event.target;
    setForm({ ...form, [name]: value });
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const response = await fetch("https://pch-services.herokuapp.com/contact", {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ ...form, token: token }),
      });
      console.log(response);

      if (response.status === 200) {
        setFormSent(true);
      }
      handleLoading();
    } catch (err) {
      handleLoading();
      console.log("error", err);
    }
  };

  const handleLoading = () => {
    setTimeout(() => {
      setLoading(false);
      setToken(null);
    }, 2000);
  };
  return (
    <Layout marginTop breadcrumb={breadcrumbs}>
      <Seo title="Contact Us" description="Call us today on 01564335999" />
      <section style={{ padding: desktop ? "0 5%" : 0 }}>
        <Grid container direction={desktop ? "row" : "column"} spacing={5}>
          <Grid
            item
            xs={desktop ? 6 : 12}
            style={{
              minHeight: desktop ? "600px" : "700px",
              position: "relative",
            }}
          >
            {formSent && !loading ? (
              <div style={{ minHeight: "100px" }}>
                <Typography variant="h2" align={desktop ? "left" : "center"}>
                  Thank You - Message Sent
                </Typography>
              </div>
            ) : (
              <form
                className={classes.formBody}
                autoComplete="off"
                onSubmit={(e) => {
                  e.preventDefault();
                  handleSubmit();
                }}
              >
                <fieldset className={classes.fieldset}>
                  <div className={classes.formLineRow}>
                    <TextField
                      margin="dense"
                      name="firstname"
                      label="First Name"
                      defaultValue=""
                      value={form["firstname"]}
                      onChange={handleTextFields}
                      required
                    />
                    <TextField
                      margin="dense"
                      name="surname"
                      label="Surname"
                      defaultValue=""
                      value={form["surname"]}
                      onChange={handleTextFields}
                      required
                    />
                  </div>
                  <div className={classes.formLineRow}>
                    <TextField
                      margin="dense"
                      name="telephone"
                      label="Telephone"
                      defaultValue=""
                      value={form["telephone"]}
                      onChange={handleTextFields}
                      required
                    />
                    <TextField
                      margin="dense"
                      name="email"
                      label="Email"
                      defaultValue=""
                      value={form["email"]}
                      onChange={handleTextFields}
                      required
                    />
                  </div>
                  <div className={classes.formLineRow}>
                    <TextField
                      select
                      margin="dense"
                      name="reason"
                      label="Subject"
                      helperText="Please select reason for contact"
                      defaultValue=""
                      value={form["reason"]}
                      onChange={handleTextFields}
                      required
                    >
                      {subjectOptions.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </div>
                  <div className={classes.formLineCol}>
                    <TextField
                      margin="dense"
                      name="message"
                      multiline
                      rowsMax={4}
                      label="Message"
                      defaultValue=""
                      value={form["message"]}
                      onChange={handleTextFields}
                      required
                    />
                  </div>
                </fieldset>
                {!loading ? (
                  <Button
                    classes={{ root: classes.submitButton }}
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={loading || !token}
                    endIcon={<SendIcon />}
                    style={{ bottom: form.message?.length > 5 ? "15%" : "25%" }}
                  >
                    Submit
                  </Button>
                ) : (
                  <CircularProgress />
                )}
                <ReCAPTCHA
                  sitekey="6LdToDAbAAAAAMLX3upzT_FCArhtkCc7GPPoXISE"
                  onChange={(token) => setToken(token)}
                  style={{
                    left: "50%",
                    position: "absolute",
                    transform: "translate(-50%, 0)",
                    bottom: "25%",
                    display: form.message?.length > 5 ? "inherit" : "none",
                  }}
                />
              </form>
            )}
          </Grid>
          <Grid item xs={desktop ? 6 : 12}>
            <GoogleMap />
          </Grid>
        </Grid>
      </section>
      <section className={classes.contactInfoBody}>
        <Grid container direction={desktop ? "row" : "column"}>
          <Grid item xs={desktop ? 6 : 12}>
            <Typography align={"center"}>
              <SvgIcon className={classes.icon}>
                {React.createElement(PhoneIcon)}
              </SvgIcon>
            </Typography>
            <CardContent classes={{ root: classes.cardContent }}>
              <Typography
                align={"center"}
                gutterBottom
                variant="h5"
                component="h2"
              >
                Telephone
              </Typography>
              <Typography
                align={"center"}
                gutterBottom
                variant="body"
                component="p"
              >
                01564335999
              </Typography>
            </CardContent>
          </Grid>
          <Grid item xs={desktop ? 6 : 12}>
            <Typography align={"center"}>
              <SvgIcon className={classes.icon}>
                {React.createElement(HouseIcon)}
              </SvgIcon>
            </Typography>
            <CardContent classes={{ root: classes.cardContent }}>
              <Typography
                align={"center"}
                gutterBottom
                variant="h5"
                component="h2"
              >
                Address
              </Typography>
              <Typography
                align={"center"}
                gutterBottom
                variant="body"
                component="p"
              >
                <span>4 Poplar Road,</span>
                <span> Dorridge,</span>
              </Typography>
              <Typography
                align={"center"}
                gutterBottom
                variant="body"
                component="p"
              >
                Solihull, B93 8DB
              </Typography>
            </CardContent>
          </Grid>
        </Grid>
      </section>
    </Layout>
  );
};

export default Contact;
