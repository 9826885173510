import { makeStyles } from "@material-ui/core/styles";

import { theme } from "../theme";

export const useStyles = makeStyles(() => ({
  formBody: {
    padding: "1rem",
    height: "100%",
    minHeight: "40vh",
    [theme.breakpoints.up("tablet")]: {
      padding: "2rem",
      paddingLeft:"0.5rem",
      minHeight: "40vh",
    },
  },
  formLineRow: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    [theme.breakpoints.up("mobile")]: {
      flexDirection: "row",
    },
  },
  formLineCol: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  submitButton: {
    position: "absolute",
    left: "50%",
    transform: "translate(-50%, 0)",
    bottom: '15%',
  },
  contactInfoBody: {
    backgroundColor: theme.palette.primary.main,
    padding: "1rem",
    margin: "5rem 0",
  },
  mobileContactInfoBody: {
    backgroundColor: theme.palette.primary.main,
  },
  icon: {
    width: "4rem",
    height: "4rem",
  },
  cardContent:{
    paddingBottom:"0 !important"
  },
  fieldset:{
    border:"none",
    padding:"1.5rem 0"
  }
}));
