import React from 'react'
import GoogleMapReact from 'google-map-react'

const defaultProps = {
  center: {
    lat: 52.37395277809049,
    lng: -1.753473048532834,
  },
  zoom: 16,
}

const AnyReactComponent = ({ text }) => <div style={{whiteSpace:'nowrap', fontWeight:600}}>{text}</div>

const GoogleMap = () => (
  <div style={{ height: '50vh', width: '100%' }}>
    <GoogleMapReact
      bootstrapURLKeys={{ key: 'AIzaSyA3ZfGLiXI1UsjT9QOhniUGC1QVttEFE2s'}}
      defaultCenter={defaultProps.center}
      defaultZoom={defaultProps.zoom}
    >
      <AnyReactComponent
        lat={52.37395277809049}
        lng={-1.753473048532834}
        text={'PCH Services'}
      />
    </GoogleMapReact>
  </div>
)

export default GoogleMap
